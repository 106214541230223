const Reels = function() {};

export default {
  methods: new Reels(),
  beforeMount: function() {
    this.currentPage = 0;
    this.getReels();
    this.$nextTick(() => {
      this.screenChanged();
    });
  },
  watch: {
    "$store.state.screen.width": function() {
      this.screenChanged();
    },
    "$store.state.screen.height": function() {
      this.screenChanged();
    },
  },
};

Reels.prototype.next = function(currentSlide, nextSlide) {
  if (Math.abs(nextSlide - currentSlide) == 1) {
    this.direction = nextSlide - currentSlide > 0 ? "right" : "left";
  } else {
    this.direction = nextSlide - currentSlide > 0 ? "left" : "right";
  }

  if (this.direction == "right") {
    this.current++;
  } else if (this.direction == "left") {
    this.current--;
  }

  this.isTheEnd = this.current === this.items.length - 1;
  if (this.isTheEnd) {
    this.getReels();
  }
};

Reels.prototype.afterChange = function() {
  this.group = this.currentGroup();
};

Reels.prototype.currentGroup = function() {
  this.first = this.current - 1;
  this.second = this.current;
  this.third = this.current + 1;

  this.media = [this.items[Math.abs(this.first)], this.items[Math.abs(this.second)], this.items[Math.abs(this.third)]];

  document.body.style.setProperty("--current-slide", `${this.current}`);

  return { first: this.first, second: this.second, third: this.third };
};

Reels.prototype.getReels = async function() {
  this.currentPage++;
  try {
    this.query = `page=${this.currentPage}`;
    this.query += `&limit=${this.limit}`;
    this.query += `&type=story`;
    this.query += `&onlyWithVideos=true`;
    this.query += `&nostatics=true`; // no statics posts
    this.req = await this.$api.get(`${process.env.VUE_APP_API_HOST}posts?${this.query}`);
    this.requestEnd();
  } catch (error) {
    this.error = error;
    this.currentPage--;
    console.error(error);
  }
  this.group = this.currentGroup();
};

Reels.prototype.requestEnd = function() {
  if (this.currentPage === 1) {
    this.items = this.req.data.posts;
  } else {
    this.items.push(...this.req.data.posts);
  }
};

Reels.prototype.screenChanged = function() {
  this.card = this.$el;
  this.cardWidth = this.card.offsetWidth;
  this.cardHeight = this.card.offsetHeight;
  this.card.style.setProperty("--card-width", `${this.cardWidth}px`);
  this.card.style.setProperty("--card-height", `${this.cardHeight}px`);
};
