<template>
  <div class="ReelsContent">
    <div class="ReelsHeader">
      <div>{{ current }}</div>
      <div>{{ group }}</div>
    </div>
    <div class="ReelsSliderWrap">
      <VueSlickCarousel v-bind="settings" class="ReelsSlider" ref="slider" @beforeChange="next" @afterChange="afterChange">
        <div :class="`ReelWrap`" v-for="(reel, index) in media" :key="`reel-${index}`">
          <Reel :post="reel" :current="current" />
        </div>
      </VueSlickCarousel>
      <Reel class="CurrentReel" :post="currentInView" :current="current" />
    </div>
  </div>
</template>

<script>
import Reels from "./reels.js";
export default {
  mixins: [Reels],
  props: ["post"],
  components: {
    Reel: () => import("./Reel.vue"),
  },
  data: function() {
    return {
      items: [],
      group: {},
      current: 0,
      number: 3,
      limit: 30,
      error: null,
      currentPage: 0,
      settings: {
        arrows: false,
        dots: false,
        mobileFirst: true,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        //vertical: true,
        //swipeToSlide: true,
        //verticalSwiping: true,
      },
      media: [1, 2, 3],
    };
  },
  computed: {
    currentInView: function() {
      return this.media[this.current];
    },
  },
};
</script>
